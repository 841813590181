import React from 'react'
import s from './main.module.scss'
import jacket from 'assets/images/jacket.png'
import { IconInstagram, IconLinkedIn } from 'components/utils/Icons'

const AboutTableContent = ({ name, children }) => {
  return (
    <div className={s.listItem}>
      <div className={s.listItemContent}>
        <h3>
          <span>{name}</span>
        </h3>
      </div>
      <div className={s.listItemContent}>
        <div className={s.contentContainer}>
          <div className={s.contentBackground}>{children}</div>
        </div>
      </div>
    </div>
  )
}

const Main = () => {
  // render

  return (
    <main className={s.main}>
      <nav className={s.nav}>
        <menu>
          {/* <a href='#about'>About</a>
          <a href='#find'>Find</a> */}
          <a href='/'>
            <div className={s.logo}>LONG</div>
          </a>
        </menu>
      </nav>

      <img src={jacket} alt='Long Sien'></img>

      {/* <div className={s.logoContainer}>
        <div className={s.logo}>Long</div>
      </div> */}

      <div className={s.txt}>
        <h1>DESIGNER,</h1>
        <h1>MAKER,</h1>
        <h1>PICTURE-</h1>
        <h1>TAKER</h1>
      </div>

      {/* about */}

      <section id='about'>
        <h2>About</h2>
        <div className={s.content}>
          <div className={s.list}>
            <AboutTableContent name={'Who?'}>
              <p>
                Wannabe polymath with too many interests and not enough time.
              </p>
            </AboutTableContent>

            <AboutTableContent name={'Where?'}>
              <p>
                Born in a refugee camp on the borders of Thailand and Cambodia,
                now living and working in Melbourne, Australia.
              </p>
            </AboutTableContent>

            <AboutTableContent name={'What?'}>
              <p>
                Professionally a UX Engineering Capability Lead at Monash
                University. Responsible for managing and expanding the UX, CX,
                and Engineering capabilities as well as delivering software
                projects.
              </p>
              <p>
                Unprofessionally a photographer, carpenter, cook... basically
                anything involving designing and/or making things.
              </p>
            </AboutTableContent>
          </div>
        </div>
      </section>

      {/* find */}

      <section id='find'>
        <h2>Find</h2>

        <div className={s.content}>
          <div className={s.list}>
            <AboutTableContent name={'Links'}>
              <p>A few places on the internet where you can find me:</p>
              <p className={s.links}>
                <a
                  href='https://www.instagram.com/longsien/'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <IconInstagram />
                  <span>Instagram</span>
                </a>
                <a
                  href='https://www.linkedin.com/in/longsien/'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <IconLinkedIn />
                  <span>LinkedIn</span>
                </a>
              </p>
            </AboutTableContent>
          </div>
        </div>
      </section>
    </main>
  )
}

export default Main
