import React from 'react'
import './app.scss'
import { Route } from 'utils/router/Router'
import Main from 'components/main/Main'
import Stuff from 'components/pages/stuff/Stuff'

function App() {
  return (
    <div className='app'>
      {/* home */}
      <Route route='/' exact transition>
        <Main />
      </Route>

      {/* stuff */}
      <Route route='stuff' transition>
        <Stuff />
      </Route>
    </div>
  )
}

export default App
