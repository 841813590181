import React, { useState } from 'react'
import Calc from './Calc'

const Calculators = () => {
  const [display, setDisplay] = useState(0)

  // render

  return (
    <>
      <Calc {...{ display, setDisplay }} />
      <Calc {...{ display, setDisplay }} />
      <Calc {...{ display, setDisplay }} />
      <Calc {...{ display, setDisplay }} />
    </>
  )
}

export default Calculators
