import React from 'react'
import ReactDOM from 'react-dom'
import './index.scss'
import App from './App.jsx'

import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'

const firebaseConfig = {
  apiKey: 'AIzaSyDNNM8OGHXubjM7IBV6tzhT5N42nXWFgcA',
  authDomain: 'longsiendotcom.firebaseapp.com',
  databaseURL: 'https://longsiendotcom.firebaseio.com',
  projectId: 'longsiendotcom',
  storageBucket: 'longsiendotcom.appspot.com',
  messagingSenderId: '980424598202',
  appId: '1:980424598202:web:1644ad82bcc3249bda5883',
  measurementId: 'G-81BM4BYE7S',
}

const app = initializeApp(firebaseConfig)
const analytics = getAnalytics(app)

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)
