import React, { useEffect, useState } from 'react'

// regex for removing the first /
const rx = /^\//
const cleanPath = (path) => `/${path.replace(rx, '')}`

// <Route />

const Route = ({ children, route, transition, exact }) => {
  const [path, setPath] = useState(cleanPath(window.location.pathname))
  const [routed, setRouted] = useState(
    exact ? path === cleanPath(route) : path.includes(cleanPath(route))
  )
  const [mounted, setMounted] = useState(false)
  const [propMount, setPropMount] = useState(false)

  // transition

  const unmountDelay = transition?.unmountDelay || 300
  const mountDelay = transition?.mountDelay || 300

  useEffect(() => {
    if (!routed) {
      setPropMount(false)
      setTimeout(() => setMounted(false), unmountDelay)
    } else {
      setTimeout(() => {
        setMounted(true)
        setTimeout(() => {
          setPropMount(true)
        }, mountDelay)
      }, mountDelay)
    }
  }, [mountDelay, mounted, routed, unmountDelay])

  useEffect(() => {
    setRouted(
      exact ? path === cleanPath(route) : path.includes(cleanPath(route))
    )
  }, [exact, path, route])

  // listeners

  useEffect(() => {
    const routeChange = (e) => {
      console.log(e)
      setPath(e.detail || e.state.page)
    }

    window.addEventListener('routechange', routeChange)
    window.addEventListener('popstate', routeChange)

    return () => {
      window.removeEventListener('routechange', routeChange)
      window.removeEventListener('popstate', routeChange)
    }
  }, [transition])

  // render

  return !transition ? (
    <>{mounted && children}</>
  ) : (
    <>
      {mounted &&
        React.Children.map(children, (child) =>
          React.cloneElement(child, {
            transition: propMount ? 'mounted' : 'unmounted',
          })
        )}
    </>
  )
}

// <Link />

const Link = ({ children, to, className }) => {
  const event = new CustomEvent('routechange', { detail: cleanPath(to) })

  const pushState = () =>
    window.history.pushState({ page: cleanPath(to) }, '', cleanPath(to))
  const emitRouteChange = () => window.dispatchEvent(event)

  const handleClick = (e) => {
    e.preventDefault()
    if (cleanPath(window.location.pathname) !== cleanPath(to)) {
      pushState()
      emitRouteChange()
    }
  }

  // render

  return (
    <a href={cleanPath(to)} onClick={handleClick} className={className}>
      {children}
    </a>
  )
}

export { Route, Link }
