import React from 'react'

// Instagram

export const IconInstagram = () => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      strokeWidth='1.25'
      stroke='#000'
      fill='none'
      strokeLinecap='round'
      strokeLinejoin='round'>
      <path d='M0 0h24v24H0z' stroke='none' />
      <rect x='4' y='4' width='16' height='16' rx='4' />
      <circle cx='12' cy='12' r='3' />
      <path d='M16.5 7.5v.001' />
    </svg>
  )
}

// LinkedIn

export const IconLinkedIn = () => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      strokeWidth='1.25'
      stroke='#000'
      fill='none'
      strokeLinecap='round'
      strokeLinejoin='round'>
      <path d='M0 0h24v24H0z' stroke='none' />
      <path d='M12 16v-5m4 5v-3a2 2 0 0 0-4 0' />
      <rect x='4' y='4' width='16' height='16' rx='2' />
      <path d='M8 11v5m0-8v.01' />
    </svg>
  )
}

// Youtube

export const IconYoutube = () => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      strokeWidth='1.25'
      stroke='#000000'
      fill='none'
      strokeLinecap='round'
      strokeLinejoin='round'>
      <path stroke='none' d='M0 0h24v24H0z' />
      <rect x='3' y='5' width='18' height='14' rx='4' />
      <path d='M10 9l5 3l-5 3z' />
    </svg>
  )
}
