import React, { useState } from 'react'

const Calc = ({ display, setDisplay }) => {
  const [input, setInput] = useState(null)
  const [numberMemory, setNumberMemory] = useState(null)
  const [operatorMemory, setOperatorMemory] = useState(null)
  const [result, setResult] = useState(null)

  // compute

  const compute = {
    '+': (a, b) => a + b,
    '-': (a, b) => a - b,
    '/': (a, b) => a / b,
    x: (a, b) => a * b,
  }

  // number press

  const number = (val) => {
    if (input !== '=') {
      // if last key wasn't "="

      if (input !== 'number' && val !== '.') {
        // if last key wasn't a number, overwrite the display with input
        setNumberMemory(val)
        setDisplay(val)
      } else {
        // if last key was a number, append new input
        setNumberMemory((f) => {
          const concat =
            f === 0 && val === 0 ? 0 : f.toString() + val.toString()
          setNumberMemory(concat)
          setDisplay(concat)
        })
      }
    } else {
      // if last key was "=", overwrite memory with new input
      setResult(null)
      setOperatorMemory(null)
      setNumberMemory(val)
      setDisplay(val)
    }

    setInput('number')
  }

  // operator press

  const operator = (val) => {
    if (input === 'number') {
      // if last key was a number
      if (result && numberMemory !== null && input !== '=') {
        // if operator exists in memory and last key wasn't "=", compute using last operator
        const computed = compute[operatorMemory](+result, +numberMemory)
        setResult(computed)
        setDisplay(computed)
      } else if (result === null && numberMemory !== null) {
        // if no operator in memory, set operator memory and get ready for next inputs
        setResult(+numberMemory)
        setDisplay(numberMemory)
        setNumberMemory(null)
      }
    }

    setOperatorMemory(val)
    setInput('operator')
  }

  // equal press

  const equal = () => {
    if (result !== null && numberMemory !== null && operatorMemory !== null) {
      const computed = compute[operatorMemory](+result, +numberMemory)
      setResult(computed)
      setDisplay(computed)
    }

    setInput('=')
  }

  // percentage press

  const computePercentage = () => {
    let computed
    switch (operatorMemory) {
      case '+':
        computed = result + (numberMemory / 100) * result
        break
      case '-':
        computed = result - (numberMemory / 100) * result
        break
      case 'x':
        computed = (numberMemory / 100) * result
        break
      case '/':
        computed = (numberMemory / result) * 100
        break
      default:
        break
    }

    return computed
  }

  const percentage = () => {
    if (result !== null && input === 'number') {
      setResult((f) => {
        const computed = computePercentage()
        setDisplay(computed)
        return computed
      })

      setNumberMemory(null)
      setInput('%')
    }
  }

  // positive/negative

  const positiveNegative = () => {
    if (numberMemory && input === 'number') {
      setDisplay(-numberMemory)
      setNumberMemory((f) => -f)
    } else if (result) {
      setDisplay(-result)
      setResult((f) => -f)
    }
  }

  // clear press

  const clear = () => {
    if (input === 'number') {
      setNumberMemory(null)
      setDisplay(0)
    } else {
      setDisplay(0)
      setResult(null)
      setInput(null)
      setNumberMemory(null)
      setOperatorMemory(null)
    }

    setInput('clear')
  }

  // render

  return (
    <div style={{ zIndex: 11 }}>
      {/* display */}
      <div className='display'>{display || 0}</div>

      {/* numbers */}
      <div className='numbers'>
        <button onClick={() => number(1)}>1</button>
        <button onClick={() => number(2)}>2</button>
        <button onClick={() => number(3)}>3</button>
        <button onClick={() => number(4)}>4</button>
        <button onClick={() => number(5)}>5</button>
        <button onClick={() => number(6)}>6</button>
        <button onClick={() => number(7)}>7</button>
        <button onClick={() => number(8)}>8</button>
        <button onClick={() => number(9)}>9</button>
        <button onClick={() => number(0)}>0</button>
        <button onClick={() => number('.')}>.</button>
      </div>

      {/* operators */}
      <div className='operators'>
        <button onClick={() => operator('+')}>+</button>
        <button onClick={() => operator('-')}>-</button>
        <button onClick={() => operator('/')}>÷</button>
        <button onClick={() => operator('x')}>×</button>
        <button onClick={percentage}>%</button>
        <button onClick={positiveNegative}>±</button>
        <button onClick={equal}>=</button>
        <button onClick={clear}>{numberMemory !== null ? 'C' : 'AC'}</button>
      </div>

      {/* pseudo console */}
      <div className='console'>
        <p>input: {input}</p>
        <p>number: {numberMemory}</p>
        <p>operator: {operatorMemory}</p>
        <p>result: {result}</p>
      </div>
    </div>
  )
}

export default Calc
