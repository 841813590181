import React from 'react'
import { Route } from 'utils/router/Router'
import Calculators from './calc/Calculators'

const Stuff = ({ transition }) => {
  console.log(transition)
  // render

  return (
    <>
      <h1 transition={transition}>Stuff</h1>
      {transition}
      <Route route='/stuff/calculators'>
        <Calculators />
      </Route>
    </>
  )
}

export default Stuff
